AUI().ready(function(){}),Liferay.Portlet.ready(function(e,t){}),Liferay.on("allPortletsReady",function(){var e=document.querySelector(".mohap-search-skeleton .pagination");if(e){var t,o,r=document.documentElement.lang||"en-US",n={"en-US":{first:"First",last:"Last",prev:"Previous",next:"Next"},"ar-SA":{first:"الأول",last:"الأخير",prev:"السابق",next:"التالي"}},r=n[r]||n["en-US"],n=e.querySelectorAll("li.page-item");3<n.length?(o=n[1].querySelector("a").getAttribute("href"),s=n[n.length-2].querySelector("a").getAttribute("href"),(t=document.createElement("li")).className="page-item",t.innerHTML=`
                <a class="page-link aegov-pagination-first" href="${o}">
                    <svg aria-hidden="true" class="rtl:rotate-180" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
                        <rect width="256" height="256" fill="none"></rect>
                        <polyline points="200 208 120 128 200 48" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline>
                        <polyline points="120 208 40 128 120 48" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline>
                    </svg>
                    ${r.first}
                </a>
            `,(o=document.createElement("li")).className="page-item",o.innerHTML=`
                <a class="page-link aegov-pagination-last" href="${s}">
                    ${r.last}
                    <svg aria-hidden="true" class="rtl:rotate-180" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
                        <rect width="256" height="256" fill="none"></rect>
                        <polyline points="56 48 136 128 56 208" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline>
                        <polyline points="136 48 216 128 136 208" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline>
                    </svg>
                </a>
            `,(s=n[0])&&(s.classList.add("aegov-pagination-previous"),s=s.querySelector(".page-link"))&&(s.innerHTML=`
                        <svg aria-hidden="true" class="rtl:rotate-180" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
                            <rect width="256" height="256" fill="none"></rect>
                            <polyline points="160 208 80 128 160 48" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline>
                        </svg>
                        <span>${r.prev}</span>
                    `),(s=n[n.length-1])&&(s.classList.add("aegov-pagination-next"),n=s.querySelector(".page-link"))&&(n.innerHTML=`
                        <span>${r.next}</span>
                        <svg aria-hidden="true" class="rtl:rotate-180" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256">
                            <rect width="256" height="256" fill="none"></rect>
                            <polyline points="96 48 176 128 96 208" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="16"></polyline>
                        </svg>
                    `),e.insertBefore(t,e.firstChild),e.appendChild(o)):e.remove();var s=".mohap-search-skeleton .portlet-search-results .pagination-bar .pagination-results",n=document.querySelector(s);if(n){let e=n.textContent;for(var[l,i]of Object.entries({"٠":"0","١":"1","٢":"2","٣":"3","٤":"4","٥":"5","٦":"6","٧":"7","٨":"8","٩":"9"}))e=e.replace(new RegExp(l,"g"),i);n.textContent=e}else console.warn("Element not found:",s)}(window.location.href.endsWith("/search?q=")||window.location.href.endsWith("/search"))&&(document.querySelector(".filters-pane").remove(),document.querySelector(".mohap-search-skeleton .results-body .empty-results-wrapper").classList.remove("hide"),document.querySelector(".mohap-search-skeleton .results-body").classList.remove("lg:col-span-9"),document.querySelector(".mohap-search-skeleton .results-body").classList.add("lg:col-span-12")),document.querySelector(".search-bar-suggestions-dropdown-menu .search-bar-suggestions-show-more")&&("ltr"==document.querySelector("html").getAttribute("dir")?document.querySelector(".search-bar-suggestions-dropdown-menu .search-bar-suggestions-show-more").textContent="Show more":document.querySelector(".search-bar-suggestions-dropdown-menu .search-bar-suggestions-show-more").textContent="عرض المزيد")});